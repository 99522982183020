





































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import PassModal from './pass-modal.vue'
import UnPassModal from './unpass-modal.vue'
import { getDicts } from '@/utils/dict'

@Component({
  components: {
    PassModal,
    UnPassModal
  }
})
export default class extends Vue {
  whichDialog = ''
  loading = false
  ownInfo: any = {} //业主信息
  houseInfo: any = {} //房屋信息
  auditDicts: any = [] //审核状态字典
  //证件类型字典
  cardDicts: any = []
  async beforeCreate() {
    // 获取相关字典
    // 审核状态
    this.auditDicts = await getDicts('AUDIT_STATUS')
    // 证件类型
    this.cardDicts = await getDicts('CARD_TYPE')
  }
  created() {
    this.getOwnerDetail(this.$route.query.id)
  }
  //查询详情
  getOwnerDetail(id: any) {
    this.loading = true
    this.$api.resident
      .getAuditDetailById(id)
      .then((res: any) => {
        const data = res.data
        if (data && data.success) {
          this.ownInfo = data.data.resident
          this.houseInfo = data.data
        } else {
          this.$message.error(data.msg)
          this.loading = false
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
  //审核状态
  stateFilter(type: string) {
    if (this.auditDicts && typeof type !== 'undefined') {
      const res = this.auditDicts.find((x: any) => x.value == type + '')
      return res && res.name
    }
  }
  //证件类型
  cardTypeFilter(type: number) {
    if (this.cardDicts && typeof type !== 'undefined') {
      const res = this.cardDicts.find((x: any) => x.value == type + '')
      return res && res.name
    }
  }
  // 返回
  goback() {
    this.$router.push(
      '/residents/owner-check?auditType=' + this.houseInfo.auditType
    )
  }
  //点击复制电话号码
  onCopy() {
    this.$message.success('复制电话号码成功')
  }
  // 打开对话框
  openDialog(name: string) {
    this.whichDialog = name
  }
  // 关闭对话框
  closeDialog() {
    this.whichDialog = ''
  }
  // 操作对话框后统一回调
  afterOpt(reason: any) {
    if (this.whichDialog == 'unpass' && !reason) {
      this.$message('请填写拒绝原因')
      return
    }
    const params = {
      auditId: this.houseInfo.auditId,
      auditStatus: 1,
      auditType: this.houseInfo.auditType,
      cardNo: this.ownInfo.cardNo,
      mobile: this.ownInfo.mobile,
      name: this.ownInfo.name,
      reason: '审核通过'
    }
    if (reason) {
      //不通过
      params.reason = reason
      params.auditStatus = 2
    }
    this.$api.resident
      .checkAudit(params)
      .then((res: any) => {
        const data = res.data
        if (data && data.success) {
          this.$message(data.msg)
          this.closeDialog()
          this.goback()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch((err: any) => {
        this.$message.error(err)
      })
  }
}
